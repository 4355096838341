<template>
  <div class="wrapper">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"></PageHeader>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>フォーム選択サンプル</template>
              <template v-slot:body>
                <transition name="page" mode="out-in" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter">
                  <router-view @footer-show="footerShow" @footer-hide="footerHide"></router-view>
                </transition>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter v-if="footer">
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <router-link class="btn btn-white" to="/user/select">
              <i class="aikon aikon-arrow_left"></i>
              アカウントを選択し直す
            </router-link>
          </li>
          <li class="listGrid-item pos-end">
            <button class="btn btn-blue" type="button">登録する</button>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import nav from '@/mixins/nav/sample';

export default {
  data: function() {
    return {
      pageName: 'ユーザー',
      footer: false,
      prevHeight: 0,
    };
  },
  methods: {
    footerShow: function() {
      this.footer = true;
    },
    footerHide: function() {
      this.footer = false;
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);
      element.style.height = this.prevHeight;
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
  mounted: function() {
    this.$router.push('/user/select', () => {});
  },
  mixins: [nav],
};
</script>

export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: 'ページ01',
          path: '/',
          includes: false,
        },
        {
          id: '02',
          name: 'ページ02',
          path: '/user',
          includes: true,
        },
        {
          id: '03',
          name: 'sample page',
          path: '/sample',
          includes: true,
        },
      ],
    };
  },
};
